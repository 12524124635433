import React from 'react'
import { Link } from 'gatsby'

const isActive = (isCurrent) => {
  return {isCurrent}
}
const ExactNavLink = props => {
  return <Link activeClassName="active" {...props} />
}

export default ExactNavLink
