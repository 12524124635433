import styled from 'react-emotion'

const ThumbNail = styled('img')`
  margin-bottom: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 190px;
  height: 100%;
  @media (max-width: 768px) {
    align-self: center;
    width: 100%;
    border-top-right-radius: 5px;
  }
`
export default ThumbNail
