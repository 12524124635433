import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import PostList from '../components/postList'

import Layout from '../components/layout'
import styled from 'react-emotion'
import CategoryLink from '../components/categoryLink'

const PageTitle = styled('div')`
  display: flex;
  justify-content: center;
  align-content: center;
`
class Category extends React.Component {
  render() {
    const { slug } = this.props.pageContext
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <PageTitle>
          <CategoryLink to={`/categories/${slug}`}>{slug}</CategoryLink>
        </PageTitle>
        <PostList posts={posts} />
      </Layout>
    )
  }
}

export default Category

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $slug } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            cover {
              childImageSharp {
                resolutions(width: 400) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
