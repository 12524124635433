import React from 'react'
import { Link } from 'gatsby'

import styled from 'react-emotion'

import { rhythm, scale } from '../utils/typography'

import Header from './header'

const Container = styled('div')`
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-left: 2%;
    margin-right: 2%;
  }
`

class Template extends React.Component {
  render() {
    let { children } = this.props
    return (
      <div>
        <Header />
        <Container>{children}</Container>
      </div>
    )
  }
}

export default Template
