import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'
import { COLOR_PALLETE } from './colors'

Wordpress2016.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
  a: {
    color: COLOR_PALLETE.PRIMARY,
  },
  '.blog-content ul, .blog-content ol': {
    marginLeft: '35px',
  },
  blockquote: {
    borderLeft: `0.32813rem solid ${COLOR_PALLETE.ACCENT}`,
  },
  body: {
    backgroundColor: '#f0f3f7',
    color: '#4F4F4F',
    fontFamily: '"Roboto", sans-serif',
  },
  '.image-caption':{
    textAlign: 'center',
    color: `${COLOR_PALLETE.LIGHTEST_BLACK}`,
    fontStyle: 'italic'
  }
})

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
