import styled from 'react-emotion'
import { rhythm } from '../utils/typography'

const PostTitle = styled('h3')`
margin-top: 0;
margin-bottom: 5px;

@media (max-width: 768px) {
  margin-top: 10px;
}
`

export default PostTitle
