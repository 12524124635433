import React from 'react'
import { Link } from 'gatsby'

import styled from 'react-emotion'
import { COLOR_PALLETE } from '../utils/colors';

const Card = styled('div')`
  box-shadow: 2px 4px 6px 0 ${COLOR_PALLETE.LIGHTEST_BLACK};
  margin-top: 20px;
  display: flex;
  border-radius: 5px;
  transition: 0.3s;
  background-color: ${COLOR_PALLETE.WHITE};
  &:hover {
    box-shadow: 4px 6px 6px 0 ${COLOR_PALLETE.LIGHTEST_BLACK};
    transition: 0.2s;
  }
  & div{
    padding: 10px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export default Card
