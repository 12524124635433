import React from 'react'
import { Link } from 'gatsby'

import Card from './card'
import ThumbNail from './thumbnail'

import styled from 'react-emotion'
import CategoryLink from './categoryLink'
import { COLOR_PALLETE } from '../utils/colors'
import PostTitle from './postTitle'

const PostInfo = styled('span')`
  font-size: 13px;
  color: '#595959';
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const PostDate = styled('span')``
const PostTimeToRead = styled('span')`
  margin-left: 5px;
`

const PostCategory = styled(CategoryLink)`
  margin-left: 25px;
  padding: 0px 5px;
`

const PostExcerpt = styled('p')`
  color: ${COLOR_PALLETE.LIGHTER_BLACK};
  margin-top: 5px;
`
const Post = ({
  slug,
  title,
  date,
  excerpt,
  thumbnail,
  category,
  timeToRead,
}) => {
  return (
    <Card key={slug}>
      <ThumbNail src={thumbnail} alt={title} />
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        <Link style={{ boxShadow: 'none' }} to={slug}>
          <PostTitle>{title}</PostTitle>
        </Link>

        <PostInfo>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PostDate> {date}</PostDate>
            <PostTimeToRead> • {timeToRead}</PostTimeToRead>
          </span>
          {category ? (
            <PostCategory to={`/categories/${category}`}>
              {category}
            </PostCategory>
          ) : null}
        </PostInfo>

        <PostExcerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
    </Card>
  )
}

export default Post
