import React from 'react'

import styled, { keyframes } from 'react-emotion'
import ExactNavLink from './link'
import { COLOR_PALLETE } from '../utils/colors'

import Home from '../assets/icons/home.svg'
import Tag from '../assets/icons/tag.svg'
import Category from '../assets/icons/book-reference.svg'

import Menu from '../assets/icons/menu.svg'
import Close from '../assets/icons/close.svg'

const Nav = styled('nav')`
  height: 40px;
  padding: 5px 20px;
  background: ${COLOR_PALLETE.PRIMARY};
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  box-shadow: 0px 2px 2px ${COLOR_PALLETE.LIGHTEST_BLACK};
  @media (max-width: 768px) {
    min-height: 40px;
    height: auto;
  }
`
const StyledLink = styled(ExactNavLink)`
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  color: ${COLOR_PALLETE.WHITE};
  text-decoration: none;
  box-shadow: none;
  margin-right: 20px;
  border: 5px solid transparent;
  transition: 0.2s;

  &.active {
    opacity: 0.6;
    transition: 0.2s;
  }
  &:not(.active):hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`

const StyledIcon = ({ component, color }) => {
  let Icon = component
  return (
    <Icon
      style={{
        height: '14px',
        width: '14px',
        fill: color || 'currentcolor',
        marginRight: '4px',
      }}
    />
  )
}

const NavMenuBtnIcon = ({ onClick, isOpen }) => {
  let component = isOpen ? Close : Menu
  return <StyledIcon component={component} color={COLOR_PALLETE.WHITE} />
}

const MobileNavMenuBtn = styled('a')`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const slide = keyframes`
  from{
    transform: translate3d(0, -30px, 0);
    }
  to{
    transform: translate3d(0,0,0);
  }
`
const MenuList = styled('span')`
  display: flex;
  @media (max-width: 768px) {
    display: ${props => props.display};
    flex-direction: column;
    animation: ${slide} 0.5s ease;
    margin-left: 25px;
  }
`
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }
  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu })
  }
  render() {
    const { showMenu } = this.state
    return (
      <Nav>
        <MobileNavMenuBtn onClick={this.toggleMenu}>
          <NavMenuBtnIcon isOpen={showMenu} />
        </MobileNavMenuBtn>
        <MenuList display={showMenu ? 'flex' : 'none'}>
          <StyledLink to="/">
            <StyledIcon component={Home} />
            Home
          </StyledLink>
          <StyledLink to="/tags">
            <StyledIcon component={Tag} />
            Tags
          </StyledLink>
          <StyledLink to="/categories">
            <StyledIcon component={Category} />
            Categories
          </StyledLink>
          <StyledLink
            to="/about"
            css={{
              marginLeft: 'auto',
            }}
          >
            About
          </StyledLink>
        </MenuList>
      </Nav>
    )
  }
}
export default Header
