import styled from 'react-emotion'
import { Link } from 'gatsby'
import { COLOR_PALLETE } from '../utils/colors';

const CategoryLink = styled(Link)`
  padding: 5px;
  background-color: ${COLOR_PALLETE.ACCENT};
  color: ${COLOR_PALLETE.BLACK};
  box-shadow: none;
  font-size: 14px;
`

export default CategoryLink
