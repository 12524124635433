export const COLOR_PALLETE = {
  DARK: "#333A62",
  PRIMARY: "#427AC2",
  LIGHT: "#32B0E4",
  ACCENT: "#84D5b0",
  
  WHITE: "white",
  LIGHTER_WHITE: "#f7f7f7",
  LIGHTEST_WHITE: "#f5f5f5",

  BLACK: "#333",
  LIGHTER_BLACK: "#666",
  LIGHTEST_BLACK: "#999",

  DEFAULT_TAG_COLOR: 'turquoise'

}

export default COLOR_PALLETE;