import React from 'react'
import Post from './post'
import get from 'lodash/get'
import styled from 'react-emotion'

const PostsContainer = styled('div')`
display: flex;
flex-flow: row wrap;
`

const PostList = ({ posts }) => {
  return (
    <PostsContainer>
      {posts.map(({ node }) => {
        const title = get(node, 'frontmatter.title') || node.fields.slug
        const slug = node.fields.slug
        const { date, category } = node.frontmatter
        const excerpt = node.excerpt
        const thumbnail = node.frontmatter.cover.childImageSharp.resolutions.src
        const timeToRead = node.timeToRead
        const postProps = {
          title,
          slug,
          date,
          excerpt,
          thumbnail,
          category,
          timeToRead: `${timeToRead} min read`
        }
        return <Post key={slug} {...postProps} />
      })}
    </PostsContainer>
  )
}

export default PostList
